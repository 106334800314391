define("ember-aria-slider/templates/components/aria-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+ckjvvA8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"rail \",[21,\"focusClass\"]]]],[9],[0,\"\\n  \"],[1,[27,\"aria-slider-thumb\",null,[[\"minValue\",\"maxValue\",\"labelledBy\",\"currentValue\"],[[23,[\"minValue\"]],[23,[\"maxValue\"]],[23,[\"labelledBy\"]],[23,[\"currentValue\"]]]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"value\"],[9],[1,[21,\"currentValue\"],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-aria-slider/templates/components/aria-slider.hbs"
    }
  });

  _exports.default = _default;
});