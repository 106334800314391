define("ember-leaflet/helpers/point", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.point = void 0;
  var isFastBoot = typeof FastBoot !== 'undefined';
  /* global L */

  var point = isFastBoot ? function () {} : function point(params) {
    var _L;

    return (_L = L).point.apply(_L, (0, _toConsumableArray2.default)(params));
  };
  _exports.point = point;

  var _default = Ember.Helper.helper(point);

  _exports.default = _default;
});