define("ember-aria-slider/templates/components/aria-slider-thumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O7epH0V7",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-aria-slider/templates/components/aria-slider-thumb.hbs"
    }
  });

  _exports.default = _default;
});