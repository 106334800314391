define("ember-local-settings/services/local-settings", ["exports", "ember-local-settings/mixins/settings-interface", "ember-local-settings/local-settings-interface"], function (_exports, _settingsInterface, _localSettingsInterface) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A service implementing a settings interface.
   *
   * @class LocalSettingsService
   * @extends Ember.Service
   * @uses SettingsInterfaceMixin
   */
  var _default = Ember.Service.extend(_settingsInterface.default, {
    /**
     * Config object, read from environment
     *
     * @private
     * @property expires
     * @type Object
     */
    config: null,

    /**
     * Top-level prefix, initialized from config
     *
     * @private
     * @property expires
     * @type Object
     * @default ''
     */
    prefix: '',

    /**
     * On initialization, read in the config property and use it to set any unset
     * properties. This config property is meant to be injected from an
     * initializer to allow easier configuration of the service, e.g. from the
     * environment.
     */
    init: function init() {
      this._super.apply(this, arguments);

      var config = this.get('config');

      if (!config) {
        // This only happens in tests when the initializer didn't run
        config = {
          adapter: 'local-memory',
          serializer: 'json'
        };
        this.set('config', config);
      }

      this.set('prefix', config.prefix || '');
    },

    /**
     * Create a "branch" -- a futher prefixed settings interface. The prefix is
     * appended to this service's prefix.
     *
     * @param prefix
     * @returns {*} settings interface
     */
    createBranch: function createBranch(prefix) {
      return _localSettingsInterface.default.create({
        config: this.get('config'),
        prefix: this.get('prefix') + prefix
      });
    }
  });

  _exports.default = _default;
});