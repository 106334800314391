define("ember-leaflet/components/polygon-layer", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-leaflet/components/polyline-layer"], function (_exports, _toConsumableArray2, _polylineLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _polylineLayer.default.extend({
    createLayer: function createLayer() {
      var _this$L;

      return (_this$L = this.L).polygon.apply(_this$L, (0, _toConsumableArray2.default)(this.get('requiredOptions')).concat([this.get('options')]));
    }
  });

  _exports.default = _default;
});