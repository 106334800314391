define("ember-leaflet/components/tile-layer", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-leaflet/components/base-layer"], function (_exports, _toConsumableArray2, _baseLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseLayer.default.extend({
    leafletRequiredOptions: Object.freeze(['url']),
    leafletOptions: Object.freeze(['minZoom', 'maxZoom', 'maxNativeZoom', 'tileSize', 'subdomains', 'errorTileUrl', 'attribution', 'tms', 'continuousWorld', 'noWrap', 'zoomOffset', 'zoomReverse', 'opacity', 'zIndex', 'unloadInvisibleTiles', 'updateWhenIdle', 'detectRetina', 'reuseTiles', 'bounds', 'className']),
    leafletEvents: Object.freeze(['loading', 'load', 'tileloadstart', 'tileload', 'tileunload']),
    leafletProperties: Object.freeze(['url:setUrl:noRedraw', 'zIndex', 'opacity']),
    createLayer: function createLayer() {
      var _this$L;

      return (_this$L = this.L).tileLayer.apply(_this$L, (0, _toConsumableArray2.default)(this.get('requiredOptions')).concat([this.get('options')]));
    }
  });

  _exports.default = _default;
});