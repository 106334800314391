define("ember-leaflet-marker-cluster/templates/marker-cluster-layer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9gLqZHwV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"marker\"],[[27,\"component\",[\"marker-layer\"],[[\"containerLayer\"],[[22,0,[]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-leaflet-marker-cluster/templates/marker-cluster-layer.hbs"
    }
  });

  _exports.default = _default;
});