define("ember-leaflet-rotated-marker/components/marker-layer", ["exports", "ember-leaflet/components/marker-layer"], function (_exports, _markerLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _markerLayer.default.extend({
    leafletOptions: Object.freeze(['rotationAngle', 'rotationOrigin']),
    leafletProperties: Object.freeze(['rotationAngle', 'rotationOrigin'])
  });

  _exports.default = _default;
});