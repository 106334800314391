define("ember-leaflet/components/polyline-layer", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-leaflet/components/array-path-layer"], function (_exports, _toConsumableArray2, _arrayPathLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _arrayPathLayer.default.extend({
    leafletOptions: Object.freeze(['smoothFactor', 'noClip']),
    createLayer: function createLayer() {
      var _this$L;

      return (_this$L = this.L).polyline.apply(_this$L, (0, _toConsumableArray2.default)(this.get('requiredOptions')).concat([this.get('options')]));
    }
  });

  _exports.default = _default;
});