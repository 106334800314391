define("ember-leaflet/templates/div-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ARDEKauX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[23,[\"isFastBoot\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"shouldRender\"]]],null,{\"statements\":[[4,\"in-element\",[[27,\"-clear-element\",[[23,[\"destinationElement\"]]],null]],[[\"guid\",\"guid\",\"nextSibling\"],[\"%cursor:0%\",\"%cursor:0%\",null]],{\"statements\":[[4,\"if\",[[23,[\"closePopup\"]]],null,{\"statements\":[[0,\"        \"],[14,1,[[27,\"action\",[[22,0,[]],[23,[\"closePopup\"]]],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-leaflet/templates/div-overlay.hbs"
    }
  });

  _exports.default = _default;
});