define("ember-leaflet-marker-cluster/components/marker-cluster-layer", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-leaflet/components/base-layer", "ember-composability-tools", "ember-leaflet-marker-cluster/templates/marker-cluster-layer"], function (_exports, _toConsumableArray2, _baseLayer, _emberComposabilityTools, _markerClusterLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseLayer.default.extend(_emberComposabilityTools.ParentMixin, {
    layout: _markerClusterLayer.default,
    leafletOptions: Object.freeze(['showCoverageOnHover', 'zoomToBoundsOnClick', 'spiderfyOnMaxZoom', 'removeOutsideVisibleBounds', 'animate', 'animateAddingMarkers', 'disableClusteringAtZoom', 'maxClusterRadius', 'polygonOptions', 'singleMarkerMode', 'spiderLegPolylineOptions', 'spiderfyDistanceMultiplier', 'iconCreateFunction']),
    leafletEvents: Object.freeze([// Marker clutster events
    'clusterclick', 'clusterdblclick', 'clustermousedown', 'clustermouseover', 'clustermouseout', 'clustercontextmenu', 'clusteradd', 'clusterremove', 'animationend', 'spiderfied', 'unspiderfied', // Marker events
    'click', 'dblclick', 'mousedown', 'mouseover', 'mouseout', 'contextmenu', 'dragstart', 'drag', 'dragend', 'move', 'remove', 'add', 'popupopen', 'popupclose']),
    createLayer: function createLayer() {
      var _this$L;

      return (_this$L = this.L).markerClusterGroup.apply(_this$L, (0, _toConsumableArray2.default)(this.get('requiredOptions')).concat([this.get('options')]));
    }
  });

  _exports.default = _default;
});