define("ember-local-settings/serializers/json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A serializer that serializes values to JSON strings.
   *
   * @class JsonSerializer
   * @extends Ember.Object
   */
  var JsonSerializer = Ember.Object.extend({
    /**
     * Serialize a value
     *
     * @param value
     * @returns {String} JSON string
     */
    serialize: function serialize(value) {
      if (value === null || value === undefined) {
        return null;
      }

      return JSON.stringify(value);
    },

    /**
     * Deserialize a value
     *
     * @param {String} JSON string
     * @returns {*} value
     */
    deserialize: function deserialize(value) {
      if (value === null || value === undefined) {
        return null;
      }

      return JSON.parse(value);
    }
  });
  JsonSerializer.reopenClass({
    toString: function toString() {
      return "ember-local-settings/serializers/json";
    }
  });
  var _default = JsonSerializer;
  _exports.default = _default;
});