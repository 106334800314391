define("ember-local-settings/serializers/noop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A serializer that passes values through as-is. Can only be used with
   * adapters that accept any type, or in environments where only strings need
   * to be stored.
   *
   * @class NoopSerializer
   * @extends Ember.Object
   */
  var NoopSerializer = Ember.Object.extend({
    /**
     * Serialize a value
     *
     * @param value
     * @returns {*}
     */
    serialize: function serialize(value) {
      return value;
    },

    /**
     * Deserializer a value
     *
     * @param value
     * @returns {*}
     */
    deserialize: function deserialize(value) {
      return value;
    }
  });
  NoopSerializer.reopenClass({
    toString: function toString() {
      return "ember-local-settings/serializers/noop";
    }
  });
  var _default = NoopSerializer;
  _exports.default = _default;
});