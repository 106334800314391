define("ember-leaflet/templates/div-overlayable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q7m7U0Rw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"popup\",\"tooltip\"],[[27,\"component\",[\"popup-layer\"],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[\"tooltip-layer\"],[[\"parentComponent\"],[[22,0,[]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-leaflet/templates/div-overlayable.hbs"
    }
  });

  _exports.default = _default;
});