define("ember-leaflet/components/wms-tile-layer", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-leaflet/components/tile-layer"], function (_exports, _toConsumableArray2, _tileLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tileLayer.default.extend({
    leafletOptions: Object.freeze(['layers', 'styles', 'format', 'transparent', 'version', 'crs']),
    createLayer: function createLayer() {
      var _this$L$tileLayer;

      return (_this$L$tileLayer = this.L.tileLayer).wms.apply(_this$L$tileLayer, (0, _toConsumableArray2.default)(this.get('requiredOptions')).concat([this.get('options')]));
    }
  });

  _exports.default = _default;
});