define("ember-local-settings/adapters/local-storage", ["exports", "ember-local-settings/adapters/web-storage"], function (_exports, _webStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Adapter that stores values in HTML5 local storage.
   *
   * @class LocalStorageAdapter
   * @extends WebStorageAdapter
   */
  var LocalStorageAdapter = _webStorage.default.extend({
    /**
     * @property storageAPI
     * @private
     */
    storageAPI: 'localStorage'
  });

  LocalStorageAdapter.reopenClass({
    toString: function toString() {
      return "ember-local-settings/adapters/local-storage";
    }
  });
  var _default = LocalStorageAdapter;
  _exports.default = _default;
});